/**
* Generated automatically at built-time (2024-08-23T14:03:25.479Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-kollektionen-ueberblick",templateKey: "sites/108-4e36a8d1-4468-4515-a170-b11be8e1566f"};
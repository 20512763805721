/**
* Generated automatically at built-time (2024-08-23T14:03:25.638Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-black-collection-outdoorkueche",templateKey: "sites/108-19be3059-f045-44c6-a3a9-53cf9cbf3842"};
/**
* Generated automatically at built-time (2024-08-23T14:03:25.707Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-kaufen-ratgeber",templateKey: "sites/108-34848434-3624-403c-a972-1e1deafc82db"};